<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotEquipment' }">设备管理</el-breadcrumb-item>
          <el-breadcrumb-item>设备组态</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="wrapper-config">
        <div class="config-title">超声波流量计组态图</div>
        <div class="main-pipeline">
          <div class="water-group" v-for="(item, index) in 20" :key="index">
            <div class="water"></div>
            <div class="water-mask"></div>
          </div>
        </div>
        <div class="branch">
          <!-- 分管道 -->
          <div class="branch-pipeline" v-for="(item, index) in listData" :key="index">
            <!-- 水流线 -->
            <div class="dotted-con">
              <div class="dotted-line" :style="'animation: animations '+item.waterSpeed+'s linear infinite normal;'" :class="item.switchText=='开'?'dotted-line-running':'dotted-line-paused'"></div>
            </div>
            <!-- <span class="branch-icon iconfont icon-shuibeng-guan"></span> -->
            <!-- 连接线1 -->
            <div class="lines1"></div>
            <div class="water-pump-unit">{{item.waterPumpUnit}}</div>
            <span class="famen-icon iconfont icon-famen" :class="item.switchText=='开'?'':'famen-icon-rotate'"></span>
            <!-- 连接线2 -->
            <div class="lines2"></div>
            <el-popover
              placement="top"
              width="200"
              trigger="click">
              <div class="popover-title">参数配置</div>
              <div class="popover-con">
                <div class="popover-item">
                  <p class="popover-label">水流速度</p>
                  <el-input type="text" size="mini" v-model="item.waterSpeed"></el-input>
                </div>
              </div>
              <div slot="reference" class="electric-valvet" @click="operaValve(item)">{{item.electricValve}}</div>
            </el-popover>
            
            <!-- 连接线3 -->
            <div class="lines3"></div>
            <div class="control">
              <span class="left-mark" :style="item.switchText=='开'?'background: #13ce66;':'background: #ff4949;'"></span>
              <span class="control-icon iconfont icon-xitongshezhi"></span>
              <div class="control-con">
                <el-switch
                  style="display: block;"
                  v-model="item.switchDisable"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="(e)=>{changeSwitch(e,item)}">
                </el-switch>
              </div>
              <span class="switch-text">{{item.switchText}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=""><!--bottom-btn-->
      <el-button type="primary" size="small" @click="$router.push('/iotEquipment')">取消</el-button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      loading: false,
      row: {},
      listData: [
        { id: 1, waterSpeed: 3, waterPumpUnit: '1#水泵机组', electricValve: '1#电动阀门', switchDisable: true, switchText: '开' },
        { id: 2, waterSpeed: 3, waterPumpUnit: '2#水泵机组', electricValve: '2#电动阀门', switchDisable: true, switchText: '开' },
        { id: 3, waterSpeed: 3, waterPumpUnit: '3#水泵机组', electricValve: '3#电动阀门', switchDisable: true, switchText: '开' },
        { id: 4, waterSpeed: 3, waterPumpUnit: '4#水泵机组', electricValve: '4#电动阀门', switchDisable: true, switchText: '开' },
        { id: 5, waterSpeed: 3, waterPumpUnit: '5#水泵机组', electricValve: '5#电动阀门', switchDisable: true, switchText: '开' },
      ]
    };
  },
  methods: {
    dayjs,
    changeSwitch(e,item) {
      if(e) {
        item.switchText = '开'
        item.waterSpeed = 3
      }else {
        item.switchText = '关'
        item.waterSpeed = 0
      }
    },
    operaValve(item) {
      
    }
  },
  mounted() {
    // if (this.$route.params.row == null) {
    //     this.$router.push('/iotEquipment')
    // } else {
    //   this.row = this.$route.params.row
    // }
  }
};
</script>

<style lang="less">
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.wrapper-config {
  height: 100%;
  position: relative;
}
.config-title {
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 26px;
  margin-top: 20px;
  position: absolute;
  top: 0;
  left: 0;
}
.main-pipeline {
  width: 1400px;
  height: 50px;
  border: 1px solid #0062a3;
  background: #FFF;
  border-radius: 30px;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
}
.water-group {
  position: relative;
}
.water {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -20px;
  left: 0px;
  background-image: linear-gradient(-180deg, #8ad2ec 13%, #238acf 91%);
  border-radius: 50% 100% 40% 60%;
}
.water-mask {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -22px;
  left: 20px;
  border-radius: 40%;
  background-color: rgba(255, 255, 255, 0.9);
  // background-color: rgba(189, 189, 189, 0.9);
  transform: translate(-50%, -70%) rotate(0);
  animation: toRotate 10s linear -5s infinite;
  z-index: 20;
  pointer-events: none;
}
.water-group:nth-child(2) {
  left: 6%;
  .water-mask {
    animation: toRotate 15s linear -8s infinite;
  }
}
.water-group:nth-child(3) {
  left: 12%;
  .water-mask {
    animation: toRotate 10s linear -6s infinite;
  }
}
.water-group:nth-child(4) {
  left: 18%;
  .water-mask {
    animation: toRotate 13s linear -5s infinite;
  }
}
.water-group:nth-child(5) {
  left: 24%;
  .water-mask {
    animation: toRotate 8s linear -2s infinite;
  }
}
.water-group:nth-child(6) {
  left: 30%;
  .water-mask {
    animation: toRotate 10s linear -6s infinite;
  }
}
.water-group:nth-child(7) {
  left: 36%;
  .water-mask {
    animation: toRotate 15s linear -8s infinite;
  }
}
.water-group:nth-child(8) {
  left: 42%;
  .water-mask {
    animation: toRotate 8s linear -2s infinite;
  }
}
.water-group:nth-child(9) {
  left: 48%;
  .water-mask {
    animation: toRotate 10s linear -6s infinite;
  }
}
.water-group:nth-child(10) {
  left: 54%;
  .water-mask {
    animation: toRotate 15s linear -8s infinite;
  }
}
.water-group:nth-child(11) {
  left: 60%;
  .water-mask {
    animation: toRotate 13s linear -5s infinite;
  }
}
.water-group:nth-child(12) {
  left: 66%;
  .water-mask {
    animation: toRotate 15s linear -4s infinite;
  }
}
.water-group:nth-child(13) {
  left: 72%;
  .water-mask {
    animation: toRotate 10s linear -6s infinite;
  }
}
.water-group:nth-child(14) {
  left: 78%;
  .water-mask {
    animation: toRotate 12s linear -2s infinite;
  }
}
.water-group:nth-child(15) {
  left: 84%;
  .water-mask {
    animation: toRotate 16s linear -7s infinite;
  }
}
.water-group:nth-child(16) {
  left: 90%;
  .water-mask {
    animation: toRotate 9s linear -5s infinite;
  }
}
.water-group:nth-child(17) {
  left: 96%;
  .water-mask {
    animation: toRotate 7s linear -3s infinite;
  }
}
@keyframes toRotate {
  50% {
    transform: translate(-50%, -70%) rotate(180deg);
  }
  100% {
    transform: translate(-50%, -70%) rotate(360deg);
  }
}

.branch {
  display: flex;
  justify-content: space-evenly;
  padding-top: 151px;
}
.branch-pipeline {
  width: 30px;
  height: 550px;
  border: 1px solid #0062a3;
  position: relative;
}
.dotted-con {
  width: 30px;
  height: 550px;
  position: relative;
  overflow: hidden;
}
.dotted-line {
  width: 10px;
  height: 1000px;
  background-image: linear-gradient(rgb(0, 125, 167) 25%, rgba(255,255,255,0) 0%);
  background-position: left;
  background-size: 5px 40px;
  background-repeat: repeat-y;
  position: absolute;
  top: 0px;
  left: 13px;
  z-index: 0;
  animation: animations 3s linear infinite normal;
}
.dotted-line-running {
  animation-play-state: running;
}
.dotted-line-paused {
  animation-play-state: paused;
}
@keyframes animations {
  from {
    transform: translate3d(0,-240px,0);
  }
  to {
    transform: translate3d(0,0,0);
  }
}

.water-pump-unit {
  width: 90px;
  height: 40px;
  border: 1px solid rgb(95, 149, 255);
  background: rgb(239, 244, 255);
  position: absolute;
  top: 150px;
  left: 60px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  padding: 0 10px;
}
.lines1 {
  width: 30px;
  height: 1px;
  background: rgb(194, 200, 213);
  position: absolute;
  top: 170px;
  left: 31px;
}
.branch-icon {
  position: absolute;
  top: 104px;
  left: -100px;
  z-index: 1;
  font-size: 150px;
  color: #0062a3;
}
.electric-valvet {
  width: 90px;
  height: 40px;
  border: 1px solid rgb(95, 149, 255);
  background: rgb(239, 244, 255);
  position: absolute;
  top: 300px;
  left: 60px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
  transition: all .3s ease;
}
.electric-valvet:hover {
  background: rgb(95, 149, 255);
  color: #FFF;
}
.lines2 {
  width: 30px;
  height: 1px;
  background: rgb(194, 200, 213);
  position: absolute;
  top: 320px;
  left: 31px;
}
.famen-icon {
  position: absolute;
  top: 308px;
  left: 3px;
  font-size: 25px;
  color: #13ce66;
  transform: rotate(90deg);
  transition: all .5s ease;
  background: #FFF;
  border-radius: 50%;
}
.famen-icon-rotate {
  color: #ff4949;
  transform: rotate(0deg);
}
.lines3 {
  width: 1px;
  height: 58px;
  background: rgb(194, 200, 213);
  position: absolute;
  top: 342px;
  left: 115px;
}
.control {
  user-select: none;
  width: 120px;
  height: 40px;
  border: 1px solid #d3d8e1;
  border-left: 0;
  background: #FFF;
  position: absolute;
  top: 400px;
  left: 60px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.06);
}
.left-mark {
  display: inline-block;
  width: 4px;
  height: 100%;
  background: #13ce66;
}
.left-mark-close {
  background: #ff4949;
}
.control-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  margin-left: 6px;
  margin-top: 2px;
  padding-left: 2px;
  line-height: 20px;
  transform: rotate(0deg);
  transition: all .3s ease;
}
.control-icon:hover {
  transform: rotate(180deg);
}
.control-con {
  margin-left: 16px;
}
.switch-text {
  font-size:14px;
  color:#333;
  margin-left: 10px;
}
.popover-title {
  font-weight: bold;
}
.popover-label::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 10px;
  background: #0062a3;
  margin-right: 5px;
}
</style>